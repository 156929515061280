export default [{
	title : 'Inscription sur le site Urbaners',
	content : `Urbaners, responsable du traitement, met en œuvre un traitement de données à caractère personnel
	ayant pour principales finalités la gestion des inscriptions, l’amélioration des services fournis, les opérations
	relatives à la gestion des clients, les opérations relatives à la prospection, l’élaboration de statistiques
	commerciales. Les informations nécessaires à la gestion du traitement sont signalées par un astérisque. En l’absence
	de réponse ou si les informations (ou l’adresse électronique) fournies sont erronées, Urbaners ne pourra pas
	traiter votre demande. Ces données sont destinées à Urbaners. Conformément à la « Loi Informatique et
	libertés » du 6 janvier 1978, vous disposez d’un droit d’interrogation, d’accès, de rectification aux données vous
	concernant, ainsi que d’un droit d’opposition pour motifs légitimes et à ce qu’elles soient utilisées à des fins de
	prospection notamment commerciale. Vous pouvez exercer vos droits en écrivant à ZippyPass par courrier électronique
	à l’adresse info@urban-challenge.fr accompagné d’une copie d’un titre d’identité signé.`
},{
	title : 'Newsletter',
	content : `Urbaners, responsable du traitement, met en œuvre un traitement de données à caractère personnel
	pour la gestion administrative et commerciale et le suivi des opérations de communication externe (notamment
	communication commerciale, actualités du site, newsletter). Les informations nécessaires à la gestion du traitement
	sont signalées par un astérisque. En l’absence de réponse ou si les informations (ou l’adresse électronique)
	fournies sont erronées, Urbaners ne pourra pas traiter votre demande. Ces données sont destinées à Running.
	Conformément à la « loi Informatique et libertés » du 6 janvier 1978, vous disposez d’un droit d’interrogation,
	d’accès, de rectification aux données vous concernant, ainsi que d’un droit d’opposition pour motifs légitimes et à
	ce qu’elles soient utilisées à des fins de prospection notamment commerciale. Vous pouvez exercer vos droits en
	écrivant à Urbaners par courrier électronique à l’adresse info@urban-challenge.fr accompagné d’une copie d’un
	titre d’identité signé.`
}];
