import React, {Component} from 'react';
import Helmet from 'react-helmet';

import cgvs from './cgvs';
import mentions from './mentions';

import './CGV.scss';

class CGV extends Component {
	render() {
		let count = 0;
		return (
			<div className="CGV">
				<Helmet>
					<title>Conditions générales | Urban Challenge</title>
				</Helmet>
				<header>
					<h1>Conditions Générales de Vente et d'Utilisation</h1>
				</header>
				<ol>
					{cgvs.map((a, i) =>
					<li key={`cgv${i}`}>
						<h2>Article {i + 1}. {a.title}</h2>
						<div>
							{a.content.map(c => {
								if (typeof c === "object") {
									return (
										<section key={c.subtitle}>
											<h3>{c.subtitle}</h3>
											{c.phrases.map(p => {
												count++;
												return <div key={count} className="richText" dangerouslySetInnerHTML={{__html: `${p}`}}/>
											})}
										</section>
									);
								}
								count++;
								return <div key={count} className="richText" dangerouslySetInnerHTML={{__html: `${c}`}}/>;
							})}
						</div>
					</li>
					)}
				</ol>
				<aside>
					<span id="mentions"/>
					<h2>Mention informatique et Liberté</h2>
					<ol>
						{mentions.map((m, i) =>
						<li key={`mentions${i}`}>
							<h3>{m.title}</h3>
							<div>{m.content}</div>
						</li>
						)}
					</ol>
				</aside>
			</div>
		);
	}
}

export default CGV;
