export default [{
	title: "Objet",
	content: [
		"Le présent site internet a pour objet de fournir des informations sur les services proposés par la société Urbaners ainsi que le choix de formules permettant d’y accéder.",
		"Urbaners propose des prestations sportives comme des BootCamps (alternance de cardio intensif et de renforcement musculaire (bras, jambes, abdos, fessiers ...), des cours à thème (corde à sauter, circuit training, cardio-boxing, yoga …), des entrainement running (entraînements dédiés au perfectionnement de la course à pieds pour toutes vos courses, trails …) et toute activités sport en affinité.",
		"La société Urbaners précise que les cours seront assurés par des professionnels de l’enseignement sportif ou des sportifs de haut niveau travaillant en autonomie et sous leur propre responsabilité.",
		"<em>NB : les bootcamps sont créés selon un concept innovant consistant à proposer des activités sportives, suivant la méthode dite « Hébert ou naturelle », en extérieur à PARIS et l’ILE DE FRANCE, pour des personnes de niveaux divers ou exclusifs et selon des horaires variés.</em>",
	]
}, {
	title: "Droits et Obligations de la société Urbaners",
	content: [
		"La société Urbaners propose en contrepartie de l’abonnement du client : <ul>"+
		"<li>des cours qualitatifs répondant au concept défini à l’article 1 ;</li>" +
		"<li>à différents horaires et lieux à PARIS et Région ILE DE FRANCE ;</li>" +
		"<li>adaptés aux souhaits et capacités physiques de l’abonné ;</li>" +
		"<li>avec le bénéfice d’une assurance de groupe souscrite auprès de la société Allianz n°60331348.</li>" +
		"</ul>",
		"Il est entendu que la société Urbaners se réserve le droit de : <ul>" +
		"<li>solliciter des pièces ou renseignements complémentaires pour confirmer l’inscription (notamment un certificat médical d’aptitude) ;</li>" +
		"<li>refuser toute inscription si le questionnaire médical, le cas échéant le certificat d’aptitude ou tout autre document demandé ne sont pas fournis ou si les mentions n’apportent pas les garanties d’aptitudes suffisantes pour suivre les cours sans risques.</li>" +
		"</ul>",
		"Urbaners peut aussi mettre fin à un abonnement en cas de violation de ces règles (notamment sur les déclarations d’aptitude médicale) ou de comportement soit parce qu’il constituerait un danger pour le client ou les tiers soit qu’il porterait gravement atteinte au bon déroulement du cours.",
		"L’instructeur est libre d’annuler ou d’interrompre un cours s’il estime que les conditions de sécurité pour les participants comme les tiers ou de bon déroulement du cours ne sont pas réunies.",
		"La société Urbaners se réserve également la faculté de suspendre ou annuler l’accès à un cours pour ces motifs ou en cas de force majeure.",
		"Ces décisions de suspension ou d’annulation d’accès peuvent être collectives ou individuelles ; dans tous les cas, elles n’ouvrent pas droit à un dédommagement.",
		"L’instructeur est en droit d’interdire l’accès à son cours 10 minutes après l’horaire prévu, sans indemnité.",
		"Le contenu des cours et le cahier des charges de leur déroulement défini par la société Urbaners n’a pas un caractère contractuel auprès du client. Ils peuvent donc être modifiés à tout moment sans ouvrir droit à dédommagement du client."
	]
}, {
	title: "Droits et Obligations du Client",
	content: [
		"Dans le cadre de son inscription (quelle que soit la formule retenue) et pour respecter les engagements de la société Urbaners auprès des prestataires, le client doit  se soumettre aux conditions suivantes : <ul>" +
		"<li>remplir loyalement les renseignements d’état civil et le questionnaire médical permettant la pratique d’activités sportives et la présentation d’un certificat médical d’aptitude si requise par Urbaners.</li>" +
		"<li>signaler tout changement de son état de santé et fournir un certificat médical si les réponses dans le questionnaire médical devaient être modifiées.</li>" +
		"<li>respecter les règles de participation et consignes définies par l’instructeur (ponctualité, tenues adaptées, emport d’eau).</li>" +
		"</ul>",
		"Le client peut assister à tous les cours dans la limite de : <ul>" +
		"<li>la validité de leur disponibilité (le nombre de place étant limitée) ;</li>" +
		"<li>la validité de la formule en cours qu’il aura souscrite ;</li>" +
		"<li>le paiement initial et les éventuels renouvellements ont bien été effectifs.</li>" +
		"</ul>",
		"Le client a le droit de mettre fin à son abonnement et être remboursé au pro rata de la période restant à courir si les prestations proposées par la société Urbaners ne respectent pas à deux reprises consécutives sur un mois les engagements compris dans le concept décrit à l’article 1.",
		"Pour les sessions gratuites promotionnelles, le client n’est pas abonné mais doit néanmoins remplir le questionnaire médical (et le cas échéant fournir le questionnaire médical d’aptitude) et les renseignements d’état civil.",
		"Le client qui a une formule de cours en illimité devra dans la mesure du possible être présent au cours afin de ne pas prendre la place de quelqu’un d’autre. Au bout de 3 absences sur les 30 derniers jours, le client se verra couper la possibilité de réserver une prochaine séance pendant 7 jours."
	]
}, {
	title: "Conditions Financières",
	content: [{
		subtitle: "Paiement",
		phrases: [
			"Le client doit s’acquitter du prix intégral de la formule choisie au moment de son inscription initiale ou de ses renouvellements.",
			"Le détail des types de formules proposées se trouve sur l’onglet dédié de ce présent site.",
			"L’acceptation des conditions générales et le règlement valent confirmation de votre choix.",
			"Les formules sont souscrites sur le site Internet de la société Urbaners (www.urban-challenge.fr).",
			"L’inscription ne devient définitive qu’après confirmation par la société Urbaners de l’encaissement du prix de la formule choisi par le client.",
			"Les renouvellements sont validés dans les mêmes conditions.",
			"Les forfaits sont strictement individuels, à peine de résiliation immédiate de l’abonnement sans mise en demeure.",
		]
	}, {
		subtitle: "Livraison",
		phrases: [
			"L’achat d’une formule fait l’objet d’une confirmation par courrier électronique dans lequel est joint la confirmation d’achat relative à la formule souscrite sur le site web."
		]
	}, {
		subtitle: "Code promotionnel",
		phrases: [
			"Tout utilisateur ou client peut obtenir, dans le cadre d’une opération marketing organisée par le site web, ou par le biais d’une carte cadeau, un code promotionnel dont les conditions d’utilisation sont les suivantes : <ul>" +
			"<li>il est utilisable par son bénéficiaire en une fois, dans les limites de sa durée de validité ;</li>" +
			"<li>il est utilisable exclusivement sur le site web ;</li>" +
			"<li>il n’est pas cumulable avec un autre code promotionnel.</li>" +
			"</ul>"
		]
	}, {
		subtitle: "Mode de règlement",
		phrases: [
			"Le mode de règlement accepté est : la carte bancaire.",
			"Ce mode de règlement permet d’acheter un abonnement en ligne immédiatement de manière ferme. Les cartes acceptées pour le paiement d’une commande sont les cartes du réseau Carte Bleue.",
			"La carte bancaire est débitée dès la validation finale de la commande."
		]
	}, {
		subtitle: "Sécurisation des paiements",
		phrases: [
			"Urbaners a confié son système de paiement à Ogone prestataire spécialisé dans la sécurisation des paiements en ligne.",
			"La totale confidentialité des informations bancaires est garantie.",
			"Les données personnelles communiquées permettent de mener à bien la transaction.",
			"Dans le cas d’une formule sans engagement avec reconduction mensuelle, elles seront enregistrées par Ogone sous réserve de l’accord du client matérialisé par un clic sur « J’autorise Ogone à conserver mes données bancaires pour le compte de Urbaners afin de faciliter les prochaines commandes ».",
			"Urbaners peut demander au client par email ou par téléphone des informations complémentaires sur son identité (carte d'identité) et l'identité du porteur de la carte bancaire qui a servi au paiement, en fonction du montant de la commande et de l'adresse de l'acheteur.",
			"Le client dispose à tout moment d'un droit d'accès et de rectification des données le concernant collectées au moment du paiement, à charge pour lui d’en faire la demande par courrier (au siège social d’Urbaners) ou par courrier électronique à l’adresse info@urban-challenge.fr"
		]
	}, {
		subtitle: "Prix",
		phrases: [
			"Le prix afférent à l’achat d’une formule est inscrit sur le site web.",
			"Le prix est affiché en euros, en montant TTC (toutes taxes comprises).",
			"Tout achat, quel que soit son origine, est payable en euros.",
		]
	}]
}, {
	title: "Renouvellement - Suspension ou Résiliation du Forfait",
	content: [
		"Le forfait peut être exceptionnellement suspendu à la demande du client sur justificatif médical en cas de blessure, maladie, grossesse, d’incapacité ou de mutation professionnelle. Il peut être résilié pour ces motifs au choix du client ; dans ce cas le forfait est remboursé au pro rata de la période restant à courir jusqu’à l’échéance.",
		"Il existe plusieurs formules Urbaners, dont les spécificités (engagement et reconduction) sont expliquées sur la fiche de chacune d’elles.",
		"Ainsi au regard des conditions de chacune de ces formules, le client peut résilier depuis son espace client, en envoyant un email à info@urban-challenge.fr ou en appelant au 01 84 74 32 00.",
		"Tous les prélèvements mensuels à venir sont stoppés.",
	]
}, {
	title: "Droit de Rétractation",
	content: [
		"Conformément aux dispositions de l’article L 121-21-8 du Code de la Consommation, le client ne dispose pas de droit de rétractation pour l’achat des activités de loisirs."
	]
}, {
	title: "Droits de l'Abonné Relatives aux Données Personnelles",
	content: [
		"La société Urbaners a déclaré auprès de la CNIL la mise en place d’un fichier constitué par les données personnelles des abonnés tirées des informations fournies lors de leur inscription sous le numéro 1447092.",
		"Le client accepte expressément que la société Urbaners utilise ce fichier, à l’exception des données médicales qui resteront confidentielles même après la résiliation de l’abonnement, à des fins promotionnelles ou commerciales soit pour son propre usage soit pour d’autres sociétés commerciales qui pourront l’exploiter pour présenter leurs propres opérations commerciales.",
		"Tout abonné dispose du droit d’accès, de modification voir de retrait des données conformément à la loi du 6 janvier 1978 en présentant sa demande à l’administrateur du site de la société Urbaners."
	]
}, {
	title: "Clauses Diverses",
	content: [
		"L’inscription à l’une des formules de la société Urbaners implique la pleine connaissance par le client des présentes conditions générales et son adhésion sans réserve.",
		"Elle ne confère aucun droit d’usage de la marque et du logo de la société Urbaners.",
		"Le mode d’inscription électronique est reconnu comme ayant pleine valeur juridique.",
		"Toutefois, les éventuels problèmes techniques de fonctionnement ou d’accès au site internet de la société Urbaners même bloquant une inscription à un abonnement ou à un cours n’ouvre droit à aucun dédommagement, quel qu’en soit la durée.",
		"Toute éventuelle résiliation ou le non-renouvellement d’un partenariat avec l’un des prestataires assurant les cours proposés par la société Urbaners ne constitue pas une cause de résiliation des abonnements ni de dédommagement.",
		"La société Urbaners aura la faculté de modifier ces conditions générales à tout moment sans que cela constitue une cause de résiliation de l’abonnement, sous réserve d’en informer les abonnés sur son site internet.",
		"L’annulation de l’une des clauses de ces conditions générales, quelqu’en soit la cause, n’emportera pas nullité de l’ensemble du contrat, dont l’application se poursuivra sous ouvrir droit à la résiliation du contrat pour l’abonné."
	]
}, {
	title: "Limitations de Responsabilité",
	content: [
		"La société Urbaners intervient en qualité de vendeur de prestations de services assurés par des professionnels indépendants.",
		"Sa responsabilité professionnelle ne couvre donc pas les décisions et comportement des instructeurs et leurs conséquences envers les abonnés, les nouveaux clients ou les tiers."
	]
}, {
	title: "Attribution de Juridiction",
	content: [
		"Les parties conviennent de soumettre le présent contrat au droit français et tout litige relatif à l'interprétation, l'application ou la résiliation du contrat à la compétence exclusive du Tribunal de Commerce de PARIS."
	]
}];
